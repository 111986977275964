































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component
export default class PrivacyPopup extends Vue {
  @Prop({
    required: true
  })
  public type!: string;
  @Prop()
  public scrollTop!: any;

  public modalOpened() {
    document.querySelector('html' as any).classList.add('open-modal');
  }
  public modalClosed() {
    document.querySelector('html' as any).classList.remove('open-modal');
    this.$emit('scroll-set', this.scrollTop);
  }
}
